<template>
	<el-dialog class="my-dialog invite-dialog" title="修改密码" :visible.sync="updatePasswordDialog" width="30%" center>
		<el-form class="my-dialog invite-dialog" ref="passForm" :model="passForm" :rules="passRule"
			label-width="7.8rem">
			<el-form-item label="修改密码：" prop="password">
				<!-- <el-input type="password" show-password placeholder="请输入"></el-input> -->
				<el-input placeholder="密码" :type="!showPass?'password':'text'" v-model="passForm.password">
					<img src="../assets/login/showpass.png" slot="suffix"
						style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;" @click="showPass = true"
						v-if="!showPass">
					<img src="../assets/login/hidepass.png" slot="suffix"
						style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;" @click="showPass = false"
						v-if="showPass">
				</el-input>
			</el-form-item>
			<el-form-item label="确认修改密码：" prop="confirm_password">
				<el-input placeholder="确认修改密码" :type="!showConPass?'password':'text'"
					v-model="passForm.confirm_password">
					<img src="../assets/login/showpass.png" slot="suffix"
						style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;"
						@click="showConPass = true" v-if="!showConPass">
					<img src="../assets/login/hidepass.png" slot="suffix"
						style="width: 18px; height: 18px;margin-right: .3rem;cursor: pointer;"
						@click="showConPass = false" v-if="showConPass">
				</el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="my-dialog-footer">
			<el-button @click="updatePasswordDialog = false">取 消</el-button>
			<el-button type="primary" @click="commitUpdatePass()">确认</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		updatePass,
		updateSelfPass
	} from '../api/user.js'
	export default {
		props:{
			self:{
				type:Boolean,
				default:false
			}
		},
		data() {
			var validateConPass = (rule, value, callback) => {
				console.log('email' + value)
				if (value == "" || !value) {
					callback(new Error('请确认密码'));
				} else {
					if (value != this.passForm.password) {
						// this.$refs.ruleForm.validateField('checkPass');
						callback(new Error('两次输入的密码不一致'));
					} else {
						callback();
					}
				}
			};
			return {
				passRule: {
					password: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					confirm_password: [{
						required: true,
						validator: validateConPass,
						trigger: 'change'
					}],
				},
				showPass:false,
				showConPass:false,
				updatePasswordDialog:false,
				passForm:{}
			}
		},
		methods: {
			close() {
				this.updatePasswordDialog = false
			},
			open(){
				this.updatePasswordDialog = true
			},
			setFormData(e){
				this.passForm = e
				console.log(this.passForm)
			},
			commitUpdatePass() {
				this.$refs["passForm"].validate(async (valid) => {
					if (valid) {
						if(this.self){
							await updateSelfPass(this.passForm)
						}else{
							await updatePass(this.passForm)
						}
						this.$message.success('修改成功')
						this.$emit('changePassword', false)
						this.updatePasswordDialog = false
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss">
	.el-input__prefix, .el-input__suffix{
		display: flex;
		align-items: center;
	}
</style>
