<template>
	<el-container>
		<el-aside>
			<div style="display: flex; justify-content: center; padding: .8rem 0;">
				<img src="../../assets/login/logo.png" style="width: 7.1rem;">
			</div>
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="vertical" text-color="#ffffff">
				<div v-for="(item,i) in routes" :key="i">
					<el-submenu :index="i + ''" v-if="item.children && !item.meta.hidden">
						<template slot="title">
							<img style="width: .9rem; height: .9rem; margin-right: .9rem;" :src="item.meta.icon">
							<span>{{item.meta.title}}</span>
						</template>
						<div v-for="(subItem,j) in item.children" :key="i + '' + j">
							<el-menu-item v-if="!subItem.meta.hidden" @click="$router.push({path:subItem.path})"
								:index="subItem.path">{{subItem.meta.title}}
							</el-menu-item>
						</div>
					</el-submenu>
					<el-menu-item v-if="!item.meta.hidden && !item.children" @click="$router.push({path:item.path})"
						:index="item.path">
						<img style="width: .9rem; height: .9rem; margin-right: .9rem;"
							:src="item.meta.icon">{{item.meta.title}}
					</el-menu-item>
				</div>
			</el-menu>
		</el-aside>
		<el-container>
			<el-header>
				<div
					style="color: #333333; font-size: .8rem; padding: 0 2.7rem; height: 3.1rem; display: flex; align-items: center; background-color: #ffffff; justify-content: space-between;">
					<div>国际云转售平台管理系统</div>
					<div style="display: flex; align-items: center;">
						<el-popover placement="bottom" width="200" trigger="click" popper-class="headerPop">
							<div class="user-login-out">
								<div @click="changePass">修改密码</div>
								<div @click="loginOut()">退出登录</div>
							</div>
							<div slot="reference" style="display: flex; align-items: center; cursor: pointer;">
								<div
									v-if="getUserName" style="width: 1.8rem; height: 1.8rem; background-color: #3B91FF; border-radius: 50%; color: #ffffff; display: flex; line-height: 1.8rem; justify-content: center;">
									{{getUserName[0]}}
								</div>
								<div style="color: #000000; font-size: .8rem;margin-left: .4rem;">{{getUserName}}</div>
								<img style="width: .9rem; height: .9rem; margin-left: .4rem;"
									src="../../assets/aside/jt.png">
							</div>
						</el-popover>
					</div>
				</div>
				<div v-if="$route.meta.title"
					style="padding: 0.8rem 2.6rem 1.1rem 2.6rem; margin-top: .1rem; background-color: #ffffff; box-shadow: box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);; position: relative; z-index: 1;">
					<div style="color: #707070; font-size: .8rem;">
						<span v-if="parent.meta && parent.meta.title">
							<span style="color: #999999;">{{parent.meta.title}}</span>
							/
						</span>
						<span style="cursor: pointer;" v-if="$route.query.fromName"
							@click="$router.push($route.query.from)">{{$route.query.fromName}} / </span>
						<span
							style="color: #666666;">{{$route.fullPath.indexOf('edit') != -1?$route.meta.editTitle:$route.meta.title}}</span>
					</div>
					<div style="color: #272727; font-size: 1rem; margin-top: .9rem;">
						{{$route.fullPath.indexOf('edit') != -1?$route.meta.editTitle:$route.meta.title}}
					</div>
				</div>
			</el-header>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
		<changePassFrom ref="layoutChangePassword" :self="true"></changePassFrom>
	</el-container>
</template>

<script>
	import VueCookies from 'vue-cookies'
	import changePassFrom from '../changePassForm.vue'
	export default {
		components:{
			changePassFrom
		},
		data() {
			return {
				activeIndex: "0",
				routes: [],
				parent: {},
				passDialog:false
			}
		},
		computed: {
			getUserName() {
				return VueCookies.get("userInfo").userName
			}
		},
		watch: {
			$route: {
				handler(newVal) {
					this.activeIndex = newVal.path
					for (let i = 0; i < newVal.matched.length; i++) {
						if (newVal.path == newVal.matched[i].path) {
							this.parent = newVal.matched[i].parent
							break
						}
					}
				},
				immediate: true
			}
		},
		methods: {
			loginOut() {
				this.$cookies.remove('userInfo')
				this.$router.push({
					path: '/login'
				})
			},
			changePass(){
				this.$refs.layoutChangePassword.open()
				this.$refs.layoutChangePassword.setFormData({target_id:VueCookies.get("userInfo").user_id})
			}
		},
		created() {
			this.$router.options.routes.forEach(e => {
				if (e.name == 'Main') {
					this.routes = e.children
				}
			})
			this.activeIndex = this.$route.path
			console.log(this.activeIndex)
		}
	}
</script>

<style lang="scss">
	.el-container {
		height: 100vh;
		background-color: #E7ECF2;

		.el-aside {
			background-color: #ffffff;
			width: 12.9rem !important;
			margin-right: .1rem;

			.el-menu {
				border-right: none;

				.el-menu-item {
					display: flex;
					align-items: center;
					padding: 1rem 1.2rem;
					font-size: .8rem;
					color: #333333 !important;
					height: 2.9rem;
				}
			}

			.el-menu-item.is-active {
				background-color: #3B91FF;
				color: #FFFFFF !important;
			}

			.el-submenu__title {
				color: #333333 !important;
				font-size: .8rem;
				height: 2.9rem;
				display: flex;
				align-items: center;
				padding: 1rem 1.2rem;
			}

			.el-submenu .el-menu-item {
				padding: 1rem 1rem 1rem 3rem;
				width: 100%;
				box-sizing: border-box;
				padding-left: 3.2rem !important;
				min-width: initial;
			}
		}

		.el-header {
			color: #ffffff;
			height: auto !important;
			padding: 0;
		}

		.el-main {
			padding: 1.3rem;
		}
	}
</style>
